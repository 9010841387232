<template>
  <section>
    <div class="super">
      <div :class="['super__container', { mobileScreen: isMobile }]">
        <div class="archive">
          <SupervisorTitle title="Архив правил вызова авто с парковки" />
          <div class="archive__pickers">
            <el-date-picker
              v-model="filters.dateFrom"
              :picker-options="options"
              type="date"
              format="dd.MM.yyyy"
              value-format="timestamp"
              placeholder="Дата с"
              size="large"
              :clearable="false"
              @change="changeDateTo"
            />
            <el-date-picker
              v-model="filters.dateTo"
              :picker-options="pickerOptionsDateTo"
              type="date"
              format="dd.MM.yyyy"
              value-format="timestamp"
              placeholder="Дата по"
              size="large"
              :clearable="false"
            />
            <IqButton
              icon="IconSearchWhite"
              color="primary"
              :size="isMobile ? 'fullWidthMedium' : 'medium'"
              class="archive__pickers-button"
              :disabled="!canApplyFilter"
              @onClick="sendQuery"
            >
              <span v-if="isMobile">Применить</span>
            </IqButton>
          </div>
        </div>

        <div v-loading="isLoading" class="super__content">
          <template v-if="isShowQuotas">
            <QuotaDay
              v-for="item in getKeysQuotaSortable"
              :key="item"
              :day-data="getQuota(item)"
              :is-archive="true"
            />
          </template>

          <div v-if="!isShowQuotas && !isLoading" class="super__empty">
            В выбранном периоде отсутствовали правила вызова
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  FETCH_SUPERVISOR_ARCHIVE,
  GET_IS_MOBILE,
  GET_LOADINGS_SUPERVISOR_STATISTIC,
  GET_SUPERVISOR_ARCHIVE,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
} from '@/store/actions'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { MILLISECOND_IN_DAY } from '@/constants/date'
import {
  archiveDateToOptions,
  finishDateToTz,
  nowTz,
  startArchiveDates,
  startArchivePickerOptions,
  startDateToTz,
} from '@/core'
import { mapActions, mapGetters } from 'vuex'
import IqButton from '@/views/ui/components/buttons/IqButton.vue'
import QuotaDay from './components/quota-day/QuotaDay.vue'
import SupervisorTitle from '@/views/supervisor/components/SupervisorTitle.vue'

export default {
  name: 'SupervisorArchive',
  components: { SupervisorTitle, QuotaDay, IqButton },
  data() {
    return {
      filters: {
        dateFrom: '',
        dateTo: '',
      },
      options: startArchivePickerOptions,
      pickerOptionsDateTo: {
        disabledDate: time =>
          Number(this.filters.dateFrom) >
            archiveDateToOptions(time.getTime()) ||
          archiveDateToOptions(time.getTime()) >
            Number(this.filters.dateFrom) + MILLISECOND_IN_DAY * 6 ||
          nowTz() < archiveDateToOptions(time.getTime()),
      },
    }
  },
  computed: {
    ...mapGetters({
      cultureList: GET_CULTURE_FROM_STATE,
      terminalId: GET_TERMINAL_CURRENT_ID,
      terminalList: GET_TERMINAL_LIST,
      quotasList: GET_SUPERVISOR_ARCHIVE,
      isLoading: GET_LOADINGS_SUPERVISOR_STATISTIC,
      isMobile: GET_IS_MOBILE,
    }),
    currentTerminal() {
      if (this.terminalList.length > 0 && this.terminalId) {
        return this.terminalList.find(i => i.id === this.terminalId)
      }

      return null
    },
    getKeysQuotaSortable() {
      return Object.keys(this.quotasList).sort().reverse()
    },
    isShowQuotas() {
      return Object.keys(this.quotasList).length > 0
    },
    canApplyFilter() {
      return this.filters.dateFrom && this.filters.dateTo
    },
  },
  watch: {
    terminalId() {
      this.filters.dateFrom = startArchiveDates().from
      this.filters.dateTo = startArchiveDates().to
      this.sendQuery()
    },
  },
  mounted() {
    this.filters.dateFrom = startArchiveDates().from
    this.filters.dateTo = startArchiveDates().to
    this.sendQuery()
  },
  methods: {
    ...mapActions({
      fetchArchive: FETCH_SUPERVISOR_ARCHIVE,
    }),
    getQuota(key) {
      return this.quotasList[key]
    },
    changeDateTo() {
      this.filters.dateTo = ''
    },
    sendQuery() {
      let filters = {
        occurs_after: startDateToTz(this.filters.dateFrom),
        occurs_before: finishDateToTz(this.filters.dateTo),
      }

      this.fetchArchive(filters)
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./styles/table-and-samples"
.archive
  display: flex
  justify-content: space-between
  align-items: center
  @media(max-width: 1199px)
    flex-direction: column

  &__pickers
    display: flex
    align-items: center
    gap: 20px
    @media(max-width: 1199px)
      flex-direction: column
      align-items: flex-start
      width: 100%
      margin-bottom: 20px

    .el-date-editor.el-input
      @media(max-width: 1199px)
        width: 100%

    &-button :deep(span)
      @media(min-width: 1200px)
        width: 20px
        margin-right: 0

.super__empty
  padding: 150px 0
  display: flex
  justify-content: center
  font-size: 20px
  font-weight: bold
  font-style: italic
  color: $main-font
</style>
